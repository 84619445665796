// import Footer from "./Footer";
import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import MerchantType from "./MerchantType";
// import resturants from "./../../src/assets/images/resturants.jpg";
// import agriculture from "./../../src/assets/images/agriculture.jpg";
// import dairyimg from "./../../src/assets/images/dairyimg.jpg";
// import medical from "./../../src/assets/images/medical.jpg";
// import SeeMoreLess from "./SeeMoreLess";
//import { GrPrevious, GrNext } from "react-icons/gr";
// import "../compenent/DomainType.css";
import SimpleImageSlider from "react-simple-image-slider";
import { StateContext } from "../../context/ContextProvider";
//Owl Carousel Libraries and Module
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//import ReactStars from "react-stars";
import noimage from "../../assets/images/noimage.jpg";
//import Rating from "react-rating";
//import Slider from "react-slick";
// Import css files
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
//import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { addToCart, getCart } from "../../compenent/helpers/localstorage";
//import { showSpinner } from "./helpers/loading";
import img1 from "../../assets/images/booking.jpg";
import img2 from "../../assets/images/Banners/banner4.png";
import img3 from "../../assets/images/Banners/banner3.jpg";
import ImageSlider from "../../compenent/ImageSlider/ImageSlider";

// import geo from "geocoder";
import { API } from "../../config/api";

function Home() {
  const [banner, setBanner] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [domainTypeL, setDomainTypeL] = useState([]);

  const { pincode } = useContext(StateContext);
  const { setOffers } = useContext(StateContext);
  const { setCart } = useContext(StateContext);

  const fixValue = "₹ Off ";

  const perValue = "% Off ";
  // const images = [
  //   { url: img1},
  //   { url: img2 },
  //   { url: img3 },
  // ];

  //const [recommended, setRecommended] = useState([]);
  const { recommended, setRecommended, distributorId, groceryMerchant } =
    useContext(StateContext);
  const {
    categoryL,
    groupcategoryL,
    setCategoryId,
    setCategoryProduct,
    setGroupCategoryId,
    setGroupCategoryProduct,
    setDomainTypeN,
    setMerchantList,
    setCategoryL,
    setGroceryMerchant,
    setGroceryId,
  } = useContext(StateContext);
  // // useEffect(()=>{ checkGroceryMerchant()},[])
  //   useEffect(() => {
  //     if (distributorId != undefined) {
  //       getBanners();
  //       // getDistributor();
  //     }
  //   }, [distributorId]);

  //get all domaintype
  const getDomainType = async () => {
    await axios.get(`${API}/merchant/Type`).then(async (response) => {
      setDomainTypeL(response.data.data);
      console.log(response.data.data, "response.data.data");
      let grocId = response.data.data.find(
        (dm) =>
          dm?.domainType !== undefined &&
          // dm?.domainType.toLowerCase() === "grocery shop"
          dm?.domainType.toLowerCase() === "grocery"
      )._id;
      setGroceryId(grocId);

      setTimeout(() => {
        getCategory(grocId);
        checkGroceryMerchant(grocId);
      }, 2000);
    });
  };
  //get category for grocery merchant
  const getCategory = async (id) => {
    await axios
      .get(`${API}/category/bymerchantTypeId/${id}`)
      .then((response) => {
        setCategoryL(response.data.data);
      });
  };

  //check grocery merchant availabe or not
  const checkGroceryMerchant = async (id) => {
    await axios
      .get(`${API}/merchant/Type/bymerchantTypeId/${id}`)
      .then((res) => {
        setGroceryMerchant(
          res.data.data.some(
            (m) =>
              m.distributorId !== null && m.distributorId._id == distributorId
          )
        );
      })
      .catch((err) => setGroceryMerchant(false));
  };
  useEffect(() => {
    if (distributorId != undefined) {
      getBanners();
      getDomainType();
    }
    //eslint-disable-next-line
  }, [recommended, distributorId]);

  // Set the date we're counting down to
  // var countDownDate = new Date("June 15, 2022 15:37:25").getTime();

  // // Update the count down every 1 second
  // var x = setInterval(function () {
  //   // Get today's date and time
  //   var now = new Date().getTime();

  //   // Find the distance between now and the count down date
  //   var distance = countDownDate - now;

  //   // Time calculations for days, hours, minutes and seconds
  //   // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //   var hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   // Output the result in an element with id="demo"
  //   document.getElementById("timer").innerHTML =
  //     hours + "h " + minutes + "m " + seconds + "s ";

  //   // If the count down is over, write some text
  //   if (distance < 0) {
  //     clearInterval(x);
  //     document.getElementById("timer").innerHTML = "EXPIRED";
  //   }
  // }, 1000);

  // function getMerchantType() {
  //   axios
  //     .get(`${API}/merchant/Type`)
  //     .then((res) => {
  //       setDomainTypeL(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  useEffect(() => {
    getOffers();
    // getMerchantType();
    //eslint-disable-next-line
  }, [pincode]);

  function getOffers() {
    //
    axios
      .get(`https://api.orbitmart.co.in/api/v1/offer/merchants/${pincode}`)
      .then((res) => {
        //
        console.log(res);
        setOffersData(res.data.data);
        setOffers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log(banner, "banner is active or not");

  const getBanners = () => {
    let id = distributorId;
    // distributorId == "" ? "62b03b1aee57bd1ef017b0fc" :
    console.log("id", id);
    axios
      .get(`https://api.orbitmart.co.in/api/v1/banner/user/${id}`)
      .then((res) => {
        var bann = res.data.data;
        var baner = [];
        for (let i = 0; i < bann.length; i++) {
          baner.push(bann[i].coverPhoto);
        }
        setBanner(baner);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getDistributor = () => {
      console.log("Called");
      // debugger
      let id = distributorId;
      axios
        .get(
          `https://api.orbitmart.co.in/api/v1/auth/merchant/byDistributorId/${id}`
        )
        .then((res) => {
          // debugger
          let data = res.data.data;
          let arr = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].recommended === true) {
              arr.push(data[i]);
            }
          }
          console.log("Arr: ", arr);
          setRecommended(arr);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDistributor();
  }, [distributorId, setRecommended]);

  // setCategoryL(data.filter(dt=>dt.domainType!=null&&dt.domainType.toLowerCase()=='grocery'))

  var banner1 = banner[0] !== undefined ? banner[0] : null;
  var banner2 = banner[1] !== undefined ? banner[1] : null;
  var banner3 = banner[2] !== undefined ? banner[2] : null;
  var banner4 = banner[3] !== undefined ? banner[3] : null;
  var banner5 = banner[4] !== undefined ? banner[4] : null;
  var banner6 = banner[5] !== undefined ? banner[5] : null;
  var banner7 = banner[6] !== undefined ? banner[6] : null;
  var banner8 = banner[7] !== undefined ? banner[7] : null;

  const addTodayDeal = (productId) => {
    console.log(productId);
    addToCart(productId[0]);
    setCart(getCart());
  };

  const navigate = useNavigate();
  //Fetch dommain type data from API

  const getMerchantById = async (id, domainType) => {
    window.sessionStorage.setItem("singleMerchantId", id);
    window.sessionStorage.setItem("domainType", domainType);
    setDomainTypeN(domainType);
    await axios
      .get(`${API}/merchant/Type/bymerchantTypeId/${id}`)
      .then((res) => setMerchantList(res.data.data));
    navigate("/merchantList");
  };

  //Category

  const handleCategory = async (id) => {
    console.log("grocery", id);
    setCategoryId(id);
    await axios
      .get(`${API}/product/categoryId/${id}`)
      .then((res) => setCategoryProduct(res.data.data));
    navigate("/categories");
  };

  //Group Category
  const handleGroupCategory = async (id) => {
    setGroupCategoryId(id);
    await axios
      .get(`${API}/product/groupCategoryId/${id}`)
      .then((res) => setGroupCategoryProduct(res.data.data));
    navigate("/groupcategories");
  };

  //Owl Carousel Settings
  const options = {
    loop: false,
    nav: true,
    navClass: ["owl-prev", "owl-next"],
    navSpeed: false,
    navElement: "div",
    navContainer: false,
    // navContainerClass: "owl-nav",
    navText: ["<", ">"],
    rewindNav: false,
    slideBy: 1,
    // margin: 45,
    margin: 30,

    // responsiveClass: true,
    items: 4,
    autoplay: false,
    autoWidth: true,
    // slideSpeed: 500,
    // paginationSpeed: 400,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    // autoplay: true,
    //autoplayTimeout:1000,

    responsiveClass: true,
    //scrollPerPage: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 6,
      },
    },
  };

  const getBannerDetails = (Bannername, num) => {
    let BannerArray = [];
    Bannername.map((subbanner) => {
      BannerArray.push(subbanner.toString());
    });
    let mainDiv = document.getElementById("bannerNum" + num);
    // !mainDiv.hasChildNodes()
    if (!mainDiv) {
      for (let i = 0; i < BannerArray.length; i++) {
        var btn = document.createElement("button");
        btn.type = " button";
        btn.setAttribute("data-bs-target", "#carouselExampleIndicators" + num);
        btn.setAttribute("data-bs-slide-to", i.toString());
        if (i === 0) {
          btn.className = "active";
          btn.setAttribute("aria-current", "true");
        }
        btn.setAttribute("aria-label", "Slide" + " " + (i + 1).toString());
        mainDiv.appendChild(btn);
      }
    }
  };

  const getImages = (Bannername, num) => {
    var special = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    var deca = [
      "twent",
      "thirt",
      "fort",
      "fift",
      "sixt",
      "sevent",
      "eight",
      "ninet",
    ];
    function stringifyNumber(n) {
      if (n < 20) return special[n];
      if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
      return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
    }
    let BannerArray = [];
    Bannername.map((subbanner) => {
      BannerArray.push(subbanner.toString());
    });
    let mainImgDiv = document.getElementById("bannerImg" + num);
    if (!mainImgDiv.hasChildNodes()) {
      for (let i = 0; i < BannerArray.length; i++) {
        var divv = document.createElement("div");
        const image = document.createElement("img");
        i == 0
          ? (divv.className = "carousel-item active")
          : (divv.className = "carousel-item");
        image.setAttribute("class", "d-block w-100");
        image.setAttribute("src", BannerArray[i].toString());
        image.setAttribute("width", "900px");
        image.setAttribute("height", "380px");
        let str = stringifyNumber(i + 1) + " " + "slide";
        image.setAttribute(
          "alt",
          str.charAt(0).toUpperCase() + str.substring(1)
        );
        divv.appendChild(image);
        mainImgDiv.appendChild(divv);
      }
    }
  };

  // merchantTypeId: "62ea4e2a5988c24ef81b097a"
  // const grocery=domainTypeL.find((dm)=>dm.domainType!==undefined && dm.domainType.toLowerCase()=='grocery')
  // if(grocery!==undefined){
  //  const data=axios
  //   .get(`${API}/merchant/Type/bymerchantTypeId/${grocery._id}`)
  //   .then((res) => res.data.data);
  //   console.log([data].some((m)=>m.distributorId!==null&&m.distributorId._id==distributorId))
  // }
  //getMerchantById(grocery._id, grocery.domainType).filter((m)=>m.distributorId!==null&&m.distributorId._id==distributorId).length>0)

  console.log("avai", groceryMerchant);
  return (
    <>
      <section className="">
        {/* <div className="container-fluid"> */}
        <div>
          {distributorId === "" ? (
            <div>
              <img
                alt=""
                className="img5"
                src="/assets/images/Banners/banner1.jpg"
                width="95%"
                style={{
                  display: "block",
                  margin: "auto",
                }}
              />
            </div>
          ) : (
            <div className="row padding border-left main-ride-hotel-container">
              <div className="mainimg inner-container-baner-rides col-lg-6 col-sm-12 p-0">
                {/* first banner */}
                {/* <div
                  id="carouselExampleIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators" id="bannerNum1">
                    {banner1 != null ? getBannerDetails(banner1, 1) : null}
                  </div>
                  <div className="carousel-inner bookYourRide" id="bannerImg1">
                    {banner1 != null ? getImages(banner1, 1) : null}
                  </div>{" "}
                  {banner1 != null ? (
                    <>
                      <div
                        className="carousel-caption slidtext d-none d-md-block"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.25)",
                        }}
                      >
                        <h5>
                          upto 50% off
                          <br /> on your first booking
                        </h5>
                        <a href="" type="button" className="coolBeans">
                          Book Our Hotel
                        </a>
                      </div>
                      <a
                        href=""
                        className="carousel-control-prev"
                        data-bs-target="#carouselExampleIndicators1"
                        data-bs-slide="prev"
                      >
                        <span
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </a>
                      <a
                        href=""
                        className="carousel-control-next"
                        data-bs-target="#carouselExampleIndicators1"
                        data-bs-slide="next"
                      >
                        <span
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </a>
                    </>
                  ) : null}
                </div> */}
                <div className="ride-bannner">
                  <div className="ride-bannner-img">
                    <img
                      alt="img"
                      className="img5"
                      src={img3}
                      width="100%"
                    />
                  </div>
                  <div className="ride-bannner-text ride-bannner-text-TEXT">
                    <h3>upto 50% off</h3>
                    <h4>on your first Ride</h4>
                    <a href="" type="button" className="coolBeans ride-bannner-text-a">
                      Book Our Ride
                    </a>
                  </div>
                </div>
                </div>

              {/* second banner */}
              <div className="mainimg inner-container-baner-rides col-lg-6 col-sm-12 p-0">
              <div className="ride-bannner">
                  <div className="ride-bannner-img">
                    <img
                      alt="img"
                      className="img5"
                      src={img1}
                      width="100%"
                    />
                  </div>
                  <div className="ride-bannner-text ride-bannner-text-TEXT">
                    <h3>upto 50% off</h3>
                    <h4>on your first booking</h4>
                    <a href="" type="button" className="coolBeans ride-bannner-text-a">
                      Book Our Hotel
                    </a>
                  </div>
                </div>
                </div>
              {/* <div className="mainimg col-lg-6 col-sm-12 p-0">
                <div
                  id="carouselExampleIndicators2"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators" id="bannerNum2">
                    {banner2 != null ? getBannerDetails(banner2, 2) : null}
                  </div>
                  <div className="carousel-inner bookYourRide" id="bannerImg2">
                    {banner2 != null ? getImages(banner2, 2) : null}
                  </div>
                  {banner2 != null ? (
                    <>
                      <div
                        className="carousel-caption slidtext d-none d-md-block"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.25)",
                        }}
                      >
                        <h5>
                          upto 50% off
                          <br /> on your first booking
                        </h5>
                        <a type="button" className="coolBeans">
                          Book Our Ride
                        </a>
                      </div>
                      <a
                        className="carousel-control-prev"
                        data-bs-target="#carouselExampleIndicators2"
                        data-bs-slide="prev"
                      >
                        <span
                          className="fa fa-angle-left"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        data-bs-target="#carouselExampleIndicators2"
                        data-bs-slide="next"
                      >
                        <span
                          className="fa fa-angle-right"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </a>
                    </>
                  ) : null}
                </div>
              </div> */}
            </div>
          )}
        </div>

        {/* <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
          {banner[0] && (
            <ImageSlider
              height="48vh"
              images={banner[0].map((url) => url )}
              showBullets={true}
              autoPlay={true}
              navSize={10}
              autoPlayDelay={2.5}
              style={{
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
                "border": "1px solid red"
              }}
            />
          )}
        </div> */}

        {/* Marchant pannel */}
        <div className="mx-5 crd bg-white rounded ">
          {/* <div className="px-5 "> */}
          <div className="">
            <div className="d-flex bd-highlight ">
              <div className=" flex-grow-1 bd-highlight"> {/* pb-4 */}
                <h4 style={{ marginTop: "10px" }}>Marchant Type's</h4>
              </div>
              <div className="p-2 bd-highlight">
                {" "}
                <Link
                  to="/domaintype"
                  className="text-bold text-success fw-bolder"
                >
                  See All
                </Link>
              </div>
            </div>

            <OwlCarousel className=" owl-carousel merchant-carts" {...options}>
              {domainTypeL.map(({ _id, domainType, image }, index) => (
                <div className="single-cart">
                  <div
                    className="item img-thumbnail tody bg-secondary merchant-cart"
                    key={index}
                    onClick={() => {
                      getMerchantById(_id, domainType);
                    }}
                  >
                    <div className="img-container">
                      <img
                        src={image}
                        alt={domainType}
                        style={{
                          width: "150px",
                          height: "150px",
                          margin: "auto",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 5 }}></div>
                    <h6 className="pt-2 text-center">{domainType}</h6>
                  </div>
                </div>
                // <Domain key={index} id={_id} domainType={domainType} src={DT[0].image} />
              ))}
            </OwlCarousel>
          </div>
        </div>

        {/* Third Banner */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators3"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum3">
              {banner3 != null ? getBannerDetails(banner3, 3) : null}
            </div>
            <div className="carousel-inner" id="bannerImg3">
              {banner3 != null ? getImages(banner3, 3) : null}
            </div>
            {banner3 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}
        <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
          {banner[0] && (
            <ImageSlider
            width="98%"
              height="48vh"
              images={banner[0].map((url) => ({ url }))}
              showBullets={true}
              autoPlay={true}
              navSize={10}
              autoPlayDelay={2.5}
              style={{
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </div>

        <div className="my-5 mx-3 static_cms">
          <div className="row">
            <div className="col-cms col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="box_cms">
                <img
                  src="/assets/images/cms/icon_cms1.png"
                  alt=""
                  className="img-responsive"
                />
                <div className="txt_cms">
                  <h2>Faster Services</h2>
                  <p>Your order delivered in few hour</p>
                </div>
              </div>
            </div>
            <div className="col-cms col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="box_cms">
                <img
                  src="/assets/images/cms/icon_cms2.png"
                  alt=""
                  className="img-responsive"
                />
                <div className="txt_cms">
                  <h2>Good Quality Product</h2>
                  <p>100% quality</p>
                </div>
              </div>
            </div>
            <div className="col-cms col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="box_cms">
                <img
                  src="/assets/images/cms/icon_cms3.png"
                  alt=""
                  className="img-responsive"
                />
                <div className="txt_cms">
                  <h2>100% Payment Secure</h2>
                  <p>Your payment are safe with us.</p>
                </div>
              </div>
            </div>
            <div className="col-cms col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="box_cms">
                <img
                  src="/assets/images/cms/icon_cms4.png"
                  alt=""
                  className="img-responsive"
                />
                <div className="txt_cms">
                  <h2>Support 24/7</h2>
                  <p>Contact us 24 hours a day</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Categories  */}
        {groceryMerchant ? (
          <div className="my-5 crd bg-white rounded Categories-container">
            {/* <div className="px-5 "> */}
            <div className="">
              <div className="d-flex bd-highlight ">
                <div className=" flex-grow-1 bd-highlight">
                  <h4 style={{ marginTop: "10px" }}>Categories</h4>
                </div>
                <div className="p-2 bd-highlight">
                  {" "}
                  <Link
                    to="/seeallcategarious"
                    className="text-bold text-success fw-bolder"
                  >
                    See All
                  </Link>
                </div>
              </div>
              <p className="p1 mt-3 mb-5">Add Popular Categories to weekly line up</p>
              <div className="mx-3 ">
                <h5 className=" txt-or fw-bold pb-2">Grocery</h5>
                <OwlCarousel className=" owl-carousel" {...options}>
                  {categoryL.map(({ _id, title, image }, index) => (
                    <div
                      className="item img-thumbnail text-center pb-2 mx-auto tody bg-secondary"
                      onClick={() => handleCategory(_id)}
                    >
                      <img
                        //  src={image ? image: noimage}
                        src={image[0] ? image[0] : noimage}
                        style={{
                          width: "150px",
                          height: "150px",
                          margin: "auto",
                        }}
                        alt={title}
                      />
                      <h6 className="text-center pt-3 pb-1">{title}</h6>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* second baner */}

        <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
          {banner[1] && (
            <ImageSlider
              width="98%"
              height="48vh"
              images={banner[1].map((url) => ({ url }))}
              showBullets={true}
              autoPlay={true}
              navSize={10}
              autoPlayDelay={2.5}
              style={{
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </div>

        {/* fouth Banner */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators4"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum4">
              {banner4 != null ? getBannerDetails(banner4, 4) : null}
            </div>
            <div className="carousel-inner" id="bannerImg4">
              {banner4 != null ? getImages(banner4, 4) : null}
            </div>
            {banner4 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators4"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators4"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}

        {/* <div className="banner-imag-with-sliders">
          {banner[2] && (
            <ImageSlider
              width="100%"
              height={450}
              images={banner[2].map((url) => ({ url }))}
              showBullets={true}
              autoPlay={true}
              navSize={100}
              autoPlayDelay={2.5}
              style={{
                height: "100%",
                width: "100%",
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </div> */}
        {/* fourth end banner */}

        <div className="mx-5  rounded bg-white">
          {/* <div className="px-5"> */}
          <div>
            <div className="d-flex bd-highlight">
              <div className=" flex-grow-1 bd-highlight">
                {" "}
                <h4 className="h43" style={{ color: "#66cdaa" }}>
                  Recommended For You
                </h4>
              </div>
              <div className="p-2 bd-highlight">
                {" "}
                <Link
                  to="/seeallRecommended"
                  className="text-bold text-success fw-bolder"
                >
                  See All
                </Link>
              </div>
            </div>
            <p className="txt-or fw-bold pb-2">Restaurant</p>
            {/* <br /> */}
            <OwlCarousel className=" owl-carousel" {...options}>
              {recommended.map((rec, index) => (
                <div
                  className="item img-thumbnail tody"
                  key={index}
                  // onClick={() => {
                  //   getMerchantById(_id, domainType);
                  // }}
                >
                  <img
                    src={rec?.avatar}
                    style={{ width: "150px", height: "150px", margin: "auto" }}
                  />

                  <h6 className="pt-2 text-center">{rec?.firmName}</h6>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        {/* fifth + 1 Banner */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators5"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum5">
              {banner5 != null ? getBannerDetails(banner5, 5) : null}
            </div>
            <div className="carousel-inner" id="bannerImg5">
              {banner5 != null ? getImages(banner5, 5) : null}
            </div>
            {banner5 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators5"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators5"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}

        <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
          {banner[3] && (
            <ImageSlider
            
            width="98%"
              height="48vh"
              images={banner[3].map((url) => ({ url }))}
              showBullets={true}
              autoPlay={true}
              navSize={10}
              autoPlayDelay={2.5}
              style={{
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </div>

        {/* fifth banner end */}

        <div className="mx-5 crd bg-white rounded">
          <div className="">
            <div className="d-flex bd-highlight">
              <div className=" flex-grow-1 bd-highlight">
                {" "}
                <h4 className="h4" style={{ color: "#66cdaa" }}>
                  Today's Deal
                </h4>
              </div>
              <div className="p-2 bd-highlight">
                {" "}
                <Link
                  to="/todaydealsseeall"
                  className="text-bold text-success fw-bolder"
                >
                  See All
                </Link>
              </div>
            </div>
            <p className="p12">Add Popular Categories to weekly line up</p>
            <OwlCarousel className=" owl-carousel" {...options}>
              {offersData
                .filter((data) => data.offerType === "TODAY")
                .map(
                  ({
                    discountType,
                    offerDiscount,
                    description,
                    productId,
                    userId,
                  }) => {
                    return (
                      <div>
                        {/* <div className="item img-thumbnail text-center pb-2 mx-auto  tody bg-secondary" style={{margin: '10px 0px'}}>
                      <div className="text-lowercase fixed-top bg-gr p-2 text-white rounded-top fw-bold">
                        {discountType === "FIXED"
                          ? offerDiscount + "" + fixValue
                          : offerDiscount + "" + perValue}
                        from{" "}
                      {userId?.firmName}
                      </div>
                    <img
                      src={product.image[0]}
                      style={{
                        paddingTop: "70px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        width: "150px",
                        height: "150px",
                        margin: "auto",
                      }}
                      alt=""
                    />
                    <h6 className="p-3 fw-bold ">{product.itemName}</h6>
                    <h6 className="fw-bold py-1">₹{product.itemPrice}</h6>
                    <button
                      type="button"
                      className="btn-sm txt-bl fw-bold w-75 text-center "
                      onClick={() => addTodayDeal(productId)}
                    >
                      Add
                    </button>
                  </div> */}
                        <div className="p-0" style={{ display: "flex" }}>
                          {productId.map((product) => {
                            return (
                              <div
                                style={{
                                  width: "13rem",
                                  margin: "0 10px",
                                  backgroundColor: "#eeeaf4",
                                  borderRadius: "5px 5px",
                                }}
                              >
                                {/* <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity value here
                              borderRadius: '5px 5px',
                            }}
                          ></div> */}
                                <div
                                  className="my-1 p-2 w-4 fw-bold"
                                  style={{
                                    width: "50%",
                                    background:
                                      "linear-gradient(150deg, #bdb3f0 6px, transparent 0) 0 5px,linear-gradient(-327deg, #bdb3f0 5px, #eeeaf4 0) 0 5px",
                                    backgroundColor: "#bdb3f0",
                                    backgroundPosition: "right bottom",
                                    backgroundRepeat: "repeat-y",
                                    backgroundSize: "10px 10px",
                                    color: "#6153a2",
                                  }}
                                >
                                  {discountType === "FIXED"
                                    ? offerDiscount + "" + fixValue
                                    : offerDiscount + "" + perValue}
                                </div>
                                <div
                                  style={{
                                    background: `url(${product.image[0]})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    objectFit: "cover",
                                  }}
                                >
                                  <div
                                    style={{
                                      background: "rgba(0, 0, 0, 0.5)",
                                    }}
                                  >
                                    <div
                                      className="fw-bold p-3"
                                      style={{ color: "white" }}
                                    >
                                      {description}
                                    </div>
                                    <div
                                      className="fw-bold p-3"
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {product.itemName}
                                    </div>
                                    <div
                                      className="fw-bold p-3"
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        background: "rgba(0, 0, 0, 0.2)",
                                      }}
                                    >
                                      <div>
                                        <del
                                          style={{
                                            fontSize: "14px",
                                            color: "white",
                                          }}
                                        >
                                          {product.itemPrice} ₹
                                        </del>{" "}
                                        <span style={{ fontSize: "18px" }}>
                                          {discountType === "FIXED"
                                            ? Math.round(
                                                product.itemPrice -
                                                  offerDiscount
                                              )
                                            : Math.round(
                                                (
                                                  product.itemPrice -
                                                  (product.itemPrice *
                                                    offerDiscount) /
                                                    100
                                                ).toFixed(2)
                                              )}{" "}
                                          ₹
                                        </span>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="btn-sm txt-bl fw-bold text-center "
                                          // onClick={() => addTodayDeal(productId)}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fs-6 p-2 text-white text-center rounded-bottom"
                                  style={{
                                    backgroundColor: "#6153a2",
                                    textAlign: "center",
                                  }}
                                >
                                  {userId?.firmName}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                )}
            </OwlCarousel>
          </div>
        </div>
        {/* <br /> */}
        {/* sixth banners        */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators6"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum6">
              {banner6 != null ? getBannerDetails(banner6, 6) : null}
            </div>
            <div className="carousel-inner" id="bannerImg6">
              {banner6 != null ? getImages(banner6, 6) : null}
            </div>
            {banner6 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators6"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators6"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}

        <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
          {banner[4] && (
            <ImageSlider
            width="98%"
              height="48vh"
              images={banner[4].map((url) => ({ url }))}
              showBullets={true}
              autoPlay={true}
              navSize={10}
              autoPlayDelay={2.5}
              style={{
                margin: "0",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </div>

        {/* end sex banner */}

        <div className="mx-5 crd rounded bg-white">
          <div className="  ">
            <div className="d-flex bd-highlight">
              <div className=" flex-grow-1 bd-highlight">
                {" "}
                <h4 className="h44" style={{ color: "#66cdaa" }}>
                  Best Deals For You
                </h4>
              </div>
              <div className="p-2 bd-highlight">
                {" "}
                <Link
                  to="/bestofferAll"
                  className="text-bold text-success fw-bolder"
                >
                  See All
                </Link>
              </div>
            </div>
            {console.log({ offersData })}
            <p className="p123">Add Popular Categories to weekly line up</p>
            <OwlCarousel
              className=" owl-carousel tody"
              style={{ marginRight: "0px" }}
              {...options}
            >
              {offersData
                .filter((data) => data.offerType === "BEST")
                .map(
                  ({
                    discountType,
                    description,
                    offerDiscount,
                    productId,
                    userId,
                  }) => (
                    <div
                      className="rounded p-0"
                      style={{
                        // width: "10rem",
                        // backgroundColor: "#eeeaf4",
                        display: "flex",
                        // flexWrap: 'wrap',
                        // border :'1px solid red'
                      }}
                    >
                      {productId.map((product) => {
                        return (
                          <div
                            style={{
                              width: "13rem",
                              margin: "0 10px",
                              backgroundColor: "#eeeaf4",
                              borderRadius: "5px 5px",
                            }}
                          >
                            <div
                              className="my-1 p-2 w-4 fw-bold"
                              style={{
                                width: "50%",
                                background:
                                  "linear-gradient(150deg, #bdb3f0 6px, transparent 0) 0 5px,linear-gradient(-327deg, #bdb3f0 5px, #eeeaf4 0) 0 5px",
                                backgroundColor: "#bdb3f0",
                                backgroundPosition: "right bottom",
                                backgroundRepeat: "repeat-y",
                                backgroundSize: "10px 10px",
                                color: "#6153a2",
                              }}
                            >
                              {discountType === "FIXED"
                                ? offerDiscount + "" + fixValue
                                : offerDiscount + "" + perValue}
                            </div>
                            {console.log({ fixValue }, { perValue })}
                            <div
                              style={{
                                background: `url(${product.image[0]})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                objectFit: "cover",
                              }}
                            >
                              <div
                                style={{
                                  background: "rgba(0, 0, 0, 0.5)",
                                }}
                              >
                                <div
                                  className="fw-bold p-3"
                                  style={{ color: "white" }}
                                >
                                  {description}
                                </div>
                                {/* <img
                            src={product.image[0]}
                            style={{
                              // paddingTop: "70px",
                              // paddingLeft: "30px",
                              // paddingRight: "30px",
                              width: "150px",
                              height: "150px",
                              margin: "auto",
                            }}
                            alt=""
                          /> */}
                                <div
                                  className="fw-bold p-3"
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  {product.itemName}
                                </div>
                                <div
                                  className="fw-bold p-3"
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                    background: "rgba(0, 0, 0, 0.2)",
                                  }}
                                >
                                  <div>
                                    <del
                                      style={{
                                        fontSize: "14px",
                                        color: "white",
                                      }}
                                    >
                                      {product.itemPrice} ₹
                                    </del>{" "}
                                    <span style={{ fontSize: "18px" }}>
                                      {discountType === "FIXED"
                                        ? Math.round(
                                            product.itemPrice - offerDiscount
                                          )
                                        : Math.round(
                                            (
                                              product.itemPrice -
                                              (product.itemPrice *
                                                offerDiscount) /
                                                100
                                            ).toFixed(2)
                                          )}{" "}
                                      ₹
                                    </span>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn-sm txt-bl fw-bold text-center"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="fs-6 p-2 text-white text-center rounded-bottom"
                              style={{
                                backgroundColor: "#6153a2",
                                textAlign: "center",
                              }}
                            >
                              {userId?.firmName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                )}
            </OwlCarousel>
          </div>
        </div>
        {/* <br />
        <br /> */}
        {/* Seventh Banner */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators7"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum7">
              {banner7 != null ? getBannerDetails(banner7, 7) : null}
            </div>
            <div className="carousel-inner" id="bannerImg7">
              {banner7 != null ? getImages(banner7, 7) : null}
            </div>
            {banner7 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators7"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators7"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}
        <div className="banner-imag-with-sliders mainimg col-lg-12 col-sm-12">
        {banner[5] && (
        <ImageSlider
        
            width="98%"
        height="48vh"
        images={banner[5].map((url) => ({ url }))}
        showBullets={true}
        autoPlay={true}
        navSize={10}
        autoPlayDelay={2.5}
        style={{
          margin: "0",
        backgroundSize: "cover",
        backgroundPosition: 'center',
      }
        }
      />)}
      </div>

        {/* seventh end  banner */}

        <div className="mx-5  rounded bg-white">
          <div className="">
            <div className="d-flex bd-highlight">
              <div className=" flex-grow-1 bd-highlight">
                {" "}
                <h4 className="h43" style={{ color: "#66cdaa" }}>
                  Group Categories
                </h4>
              </div>
              <div className="p-2 bd-highlight">
                {" "}
                <Link
                  to="/seeallgroupcategories"
                  className="text-bold text-success fw-bolder"
                >
                  See All
                </Link>
              </div>
            </div>
            {console.log(groupcategoryL, "groupcategoryL")}
            <p className="p1234">Add Popular Categories to weekly line up</p>
            <OwlCarousel className=" owl-carousel" {...options}>
              {groupcategoryL.map(({ _id, title, image }, index) => (
                <div
                  className="item img-thumbnail text-center pb-2 mx-auto tody bg-secondary"
                  key={index}
                  onClick={() => handleGroupCategory(_id)}
                >
                  {/* <div className="item img-thumbnail text-center pb-2"> */}
                  {console.log(image)}
                  <img
                    src={image ?? noimage}
                    style={{ width: "150px", height: "150px", margin: "auto" }}
                  />

                  <h6 className="text-center pt-3 pb-1">{title}</h6>
                  {/* <button
                    type="button"
                    className="btn-sm txt-bl fw-bold align-self-center"
                   
                  >
                    shop now
                  </button> */}
                  {/* <button type="button" className="btn-sm btn-success ">
                    shop now
                  </button> */}
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        {/* <br />
        <br /> */}
        {/* last banner */}
        {/* <div className="mainimg col-lg-12 col-sm-12 p-4">
          <div
            id="carouselExampleIndicators8"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" id="bannerNum8">
              {banner8 != null ? getBannerDetails(banner8, 8) : null}
            </div>
            <div className="carousel-inner" id="bannerImg8">
              {banner8 != null ? getImages(banner8, 8) : null}
            </div>
            {banner8 != null ? (
              <>
                <a
                  className="carousel-control-prev"
                  data-bs-target="#carouselExampleIndicators8"
                  data-bs-slide="prev"
                >
                  <span className="fa fa-angle-left" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-bs-target="#carouselExampleIndicators8"
                  data-bs-slide="next"
                >
                  <span className="fa fa-angle-right" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </>
            ) : null}
          </div>
        </div> */}


        {/* last banner */}
      </section>
    </>
  );
}
export default Home;
