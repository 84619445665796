// ImageSlider.js
import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import "./imageSlider.css"

const ImageSlider = ({
  width = 896,
  height = 504,
  images = [],
  showBullets = true,
  showNavs = true,
  loop = true,
  autoPlay = true,
  autoPlayDelay = 3,
}) => {
  return (
    <div className='img-container-slider'>
      <SimpleImageSlider
      className="ImageSlider"
        width={width}
        height={height}
        images={images}
        showBullets={showBullets}
        showNavs={showNavs}
        loop={loop}
        autoPlay={autoPlay}
        autoPlayDelay={autoPlayDelay}
      />
    </div>
  );
};

export default ImageSlider;
