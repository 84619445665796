import React from "react" ;
import { Link } from "react-router-dom";
import "./Footer.css";
import { BorderLeft } from "@material-ui/icons";

const Footer = () => {
  const footerLists = {
    marginTop: "10px",
  };
  return (
    <div>
      <footer id="dk-footer" className="dk-footer">
        <div className="container-fluid">
          <div className="row footer-container">
            <div className="col-md-6 col-lg-3 col-12" style={{display: 'block'}}>
              {/* <div className=" col-md-4 col-lg-6 "> */}
              <div className="followus">
                <a href="index.html" className="footer-logo">
                  <img
                    src="/assets/images/orbitlog.png"
                    alt="reload it"
                    className="mt-2"
                  />
                </a>
                <p className="footer-info-text footer-text-text">
                  {/* We are as fast as air and as trustworthy as you heart
                  available for your 24*7 needs */}
                  {/* <h3>Swift as air & trustworthy  <br /> as your heart</h3> */}
                  <br />
                  {/* Available 24*7 */}
                </p>
                <div className="footer-social-link">
                  <h3>Follow us</h3>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/growupsindhu/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/orbitmartkudal?s=09"
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <i className="ri-twitter-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/orb1618/"
                        target="_blank"
                        className="text-decoration-none"
                        rel="noreferrer"
                      >
                        <i className="ri-instagram-line"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-12" style={{display: 'block'}}>
                  <div className="footer-sec-section">
                    <div className="section-heading-use">
                      <h3>Useful as</h3>
                      {/* <span className="animate-border border-black"></span> */}
                    </div>

                    <ul style={footerLists}>
                      <li style={{ marginTop: "8px" }}>
                        <Link
                          className=""
                          to="/about"
                        >
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=""
                          to="/termsandcondition"
                        >
                          {/* Terms & conditions */}
                          Terms & conditions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className="text-white text-decoration-none"
                          to="/privacypolicies"
                        >
                          Terms & conditions
                          Privacy policies
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          className=""
                          to="/privacypolicies"
                          // target="_blank"
                        >
                          Privacy policies
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=""
                          to="/Faq"
                        >
                          Faq
                        </Link>
                      </li>
                      <li>
                        <Link
                          // className="text-white text-decoration-none"
                          to="/companycontact"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12" style={{display: 'block'}}>
              {/* <div className="col-md-8 col-lg-6 "> */}
              <div className="row w-auto" style={{borderLeft: 'none'}}>
                {/* <div className="col-md-6 col-lg-6 col-sm-6"> */}
                {/* <div className="col-md-6 col-lg-6 col-sm-6"> */}
                <div className="col-md-12 col-lg-12 col-12">
                  {/* <div className="static_cms">
                    <div className="row"> */}
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 gettxt">
                        <h6>Get access to exclusive deals</h6>
                        <p>Only the best deals reach your inbox</p>
                      </div> */}
                  {/* <div style={{ backgroundColor: "whitesmoke" }}>
                        <h6
                          style={{
                            color: "black",
                            fontSize: "18px",
                            fontWeight: "bold",
                            paddingTop: "5px",
                          }}
                        >
                          Get access to exclusive deals
                        </h6>
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Only the best deals reach your inbox
                        </p>
                      </div> */}
                  {/* <div className="col-lg-6 col-md-6 col-sm-6"> */}
                  {/* <div style={{ padding: "10px" }}>
                        <form className="form-inline d-flex">
                          <div className="centralize">
                            <div className="input-block">
                              <input
                                type="text"
                                name="input-text"
                                id="input-text"
                                required
                                spellcheck="false"
                                style={{ width: "180px" }}
                              />
                              <span className="placeholder">
                                Enter your email here
                              </span>
                            </div>
                          </div>
                          <div className="dis_container">
                            <a type="button" className="coolbt">
                              Notify me
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}
                  <div className="plystr my-3 mx-auto px-auto w-100 ">
                    <p>Download orbit mart app for exciting offer's</p>
                    <div className="hrply w-75 mx-auto "></div>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.orbitmart.gogrocer'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src="/assets/images/app-store-png-logo-33123.png"
                        alt=""
                        width="50%"
                      />
                    </a>
                    <img
                      src="/assets/images/app-store-png-logo-0.png"
                      alt=""
                      width="50%"
                      className="appleIcon"
                    />
                    <p className="coms">coming soon...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            &copy; Copyright 2024{" "}
            <strong>
              <span>orbitmarts</span>
            </strong>
            . All Rights Reserved.
          </div>
        </div>
        <div id="back-to-top" className="back-to-top">
          <button
            id="back-to-top"
            className="btn btn-dark btn-lg back_to_top"
            title="Back to Top"
          >
            <i className="ri-arrow-up-s-line"></i>
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
