import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import { StateContext } from "../context/ContextProvider";
import { StateContext } from "../../context/ContextProvider";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import {
  addToCart,
  deleteFromCart,
  deleteLocalStorage,
  getCart,
} from "../../compenent/helpers/localstorage";

import NoProduct from "../../compenent/NoProduct";
import noimage from "../../assets/images/noimage.jpg";
import orbit from "../../assets/images/orbit.png";
import { showErrorMsg, showSuccessMsg } from "../../compenent/helpers/message";
import { showLoader } from "../../compenent/helpers/loading";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { API } from "../../config/api";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import ProductDetailsData from "../../compenent/ProductDetailsData";
import ReactPaginate from "react-paginate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ProductList = () => {
  const { productList, merproductcategory } = useContext(StateContext);
  const [mainValue, setMainValue] = useState("See All");
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState(productList);
  const [selectedProduct] = useState("");
  const activeInactive = true;

  let navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    const getProductsPagination = async () => {
      const id = window.sessionStorage.getItem("merchantId");
      const config = {
        headers: {
          "x-token": token
        },
      };
      const res = await axios.get(`https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}`, config);
      setProducts(res?.data?.data);
    };
    fetchProducts(currentPage);
    getProductsPagination();
  }, [currentPage]);


  // =============================category data start ===========
  let emptObj = [];
  const localstorecart = merproductcategory;
  for (let i = 0; i < localstorecart.length; i++) {
    emptObj.push({
      title: localstorecart[i].title,
    });
  }
  emptObj.push({
    title: "See All",
  });
  emptObj.reverse();
  const handleOptions = (gcategory) => {
    console.log(gcategory);
    setMainValue(gcategory.title);
    // setMainValue(gcategory._id);
    console.log(gcategory.title);
    setProducts(productList);
    console.log(productList);
    if (gcategory.title !== "See All") {
      const result = productList.filter(
        (data) => data.categoryId.title === gcategory.title
      );
      setProducts(result);
      console.log(result);
    }
  };

  const { cart, setCart } = useContext(StateContext);
  const [banner, setBanner] = useState([]);

  var timeout;

  // const displayErrMsg = (setErrMsg, timeout) => {
  //   setErrMsg(true);
  //   clearTimeout(timeout);
  //   timeout = setTimeout(function () {
  //     setErrMsg(false);
  //   }, 1000);
  //   return timeout;
  // };

  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    }, 1000);
    return timeout;
  };

  const [errMsg] = useState(false);
  const [loading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [popup, setPopup] = useState(false);
  const [merchantA, setMerchantA] = useState("");
  const [merchantB, setMerchantB] = useState("");
  const [isChangeCproduct, setIsChangeProduct] = useState([]);
  const handleAddToCart = (product) => {
    if (merchantExist(product.merchantId)) {
      addToCart(product);
      setCart(prevCart => {
        const updatedCart = getCart();
        return updatedCart
      });
      setSuccessMsg(true);
      setMsg("Product Added SuccessFully");

      timeout = displaySuccessMsg(setSuccessMsg, timeout);
    } else {
      console.log(product);
      setMerchantA(
        cart.filter((p) => p.merchantId !== product?.merchantId)[0].firmName
      );
      setMerchantB(localStorage.getItem("firmName"));
      setIsChangeProduct(product);
      setPopup(true);
    }
  };

  const merchantExist = (merId) => {
    return cart.every(function (p) {
      return p.merchantId === merId;
    });
  };

  const handleQtyChange = (e, product, action) => {
    const cart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];

    cart.forEach((cartItem) => {
      if (cartItem._id === product._id) {
        if (action === "+") {
          //increment
          cartItem.count += 1;
        } //decrement
        else {
          if (cartItem.count === 1) {
            deleteFromCart(product);
            setCart(getCart());
            return false;
          } else {
            cartItem.count -= 1;
          }
        }
        setSuccessMsg(true);
        setMsg("Product updated SuccessFully");

        timeout = displaySuccessMsg(setSuccessMsg, timeout);
      }
    });

    localStorage.setItem("cart", JSON.stringify(cart));
    setCart(getCart());
  };

  const getMerchantBanner = () => {
    const MId = window.sessionStorage.getItem("merchantId");
    console.log(MId);
    axios
      .get(`https://api.orbitmart.co.in/api/v1/banner/user/${MId}`)
      .then((res) => {
        let data = res.data.data;
        setBanner(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMerchantBanner();
  }, []);

  const handleResetCart = () => {
    deleteLocalStorage("cart");
    addToCart(isChangeCproduct);
    setCart(getCart());
    setPopup(false);
  };
  const cartResetPopup = () => {
    return (
      <Modal
        open={popup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Replace cart item?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textTransform: "lowercase" }}
          >
            Your cart contains dishes from <b>{merchantA}</b>.Do you want to
            discard the selection and add dishes from <b>{merchantB}</b>?
          </Typography>
          <Box sx={{ px: 5, mx: 5, pt: 2, display: "flex" }}>
            <Button onClick={() => setPopup(false)} variant="contained">
              NO
            </Button>
            <Button onClick={handleResetCart} variant="contained">
              YES
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const fetchProducts = async (page) => {
    const config = {
      headers: {
        // "x-token":
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmIyY2JmYjA0OTczYjI3MzA0N2ZiYzYiLCJyb2xlIjoiTUVSQ0hBTlQiLCJkYXRlIjoiMjAyMi0wNi0yMlQxMDoyMToxNC4zNTNaIiwiaWF0IjoxNjU1ODkzMjc0fQ.FnZc_rzFv8jJ9nJH41cXyDlwytFLbxTmec3wKy2ksh8",
        "x-token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmRlYTkwZDA4ZDg5NTQ0NDI0MWNjMzgiLCJyb2xlIjoiTUVSQ0hBTlQiLCJkYXRlIjoiMjAyMi0wNy0yOFQxMDowNTowNS4yMDBaIiwiaWF0IjoxNjU5MDAyNzA1fQ.37CWwygF_BGtRlU3gsissMFV9uX6s3YAtj55E_QGK24",
      },
    };
    const id = window.sessionStorage.getItem("merchantId");
    const response = await fetch(
      `${API}/product/byMerchant/${id}?page=${page}`,
      config
    );
    const data = await response.json();
    setProducts(data.data);
    console.log("products", data.data.products);
    return data.products;
  };

  const handlePageClick = async (page) => {
    setCurrentPage(page.selected + 1);
  };

  return (
    <div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="true"
      >
        <div className="carousel-indicators">
          {banner.map((data, index) => {
            return (
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current="true"
                aria-label={`Slide ${index + 1}`}
              ></button>
            );
          })}
        </div>

        {/* <div className="carousel-inner">
          {banner.length > 0 ? (
            banner.map((data, index) => (
              <div
                className={
                  index === 0 ? "carousel-item active" : "carousel-item "
                }
                key={index}
              >
                <img
                  src={data.coverPhoto[0]}
                  className="d-block w-100"
                  height="380px"
                  alt="First slide"
                />
              </div>
            ))
          ) : (
            <div className="">
              <img
                src={orbit}
                className="d-block w-100"
                height="380px"
                alt="Banner Not Available"
              />
            </div>
          )}
        </div> */}

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="fa fa-solid fa-chevron-left"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="fa fa-solid fa-chevron-right"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="row">
        <div className="col-lg-2 pt-5">
          <div className="scrolleffect d-flex flex-column bd-highlight mb-3 overflow-auto  d-inline-block scrollbar scrollbar-lady-lips">
            {emptObj.map((gcategory, index) => (
              <div
                key={index}
                className={`text-dark p-2 bd-highlight border-bottom font-weight-normal hover-overlay
               ${mainValue === gcategory.title ? "text-selected" : ""}
             
              `}
                onClick={() => handleOptions(gcategory)}
              >
                {gcategory.title}
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-10 ">
          <div className="mx-3 crd bg-white rounded">
            <div className="px-5 pb-5 ">
              <div className=" bg-white rounded ">
                <div className=" flex-grow-1 bd-highlight pb-3">
                  <h4 style={{ marginTop: "10px" }}>Products</h4>
                </div>

                <div className="row">
                  {errMsg && showErrorMsg(msg, errMsg)}
                  {loading && showLoader()}
                  {successMsg && showSuccessMsg(msg, successMsg)}
                  {popup && cartResetPopup()}
                  {products.length > 0 ? (
                    products.map((product, index) => (
                      <div className="product-container d-flex bd-highlight">
                        <div
                          className="card bg-secondary  w-100 rounded-3 text-light  bs"
                          style={{
                            height: "250px",
                          }}
                          key={index}
                        >
                        
                          <div style={{ width: "100%" }}>
                            {selectedProduct && (
                              <ProductDetailsData product={selectedProduct} />
                              //this is where you call productDetails component with the product
                            )}
                            <div
                              style={{
                                width: "100%",
                                height: "250px",
                                position: "relative",
                              }}
                            >
                                  <div // yha hum kuch time ke liye stylie hide kar rahe hain
                                  // style={{
                                  //   background:
                                  //   product?.merchantId?.ActiveInactive &&
                                  //   product?.merchantId?.distributorStatus &&
                                  //   product?.merchantId?.distributorActiveInactive &&
                                  //   product?.merchantId?.adminActiveInactive 
                                  //       ? ""
                                  //       : "rgba(0, 0, 0,0.4)",
                                  //   width:
                                  //   product?.merchantId?.ActiveInactive &&
                                  //   product?.merchantId?.distributorStatus &&
                                  //   product?.merchantId?.distributorActiveInactive &&
                                  //   product?.merchantId?.adminActiveInactive 
                                  //       ? "auto"
                                  //       : "100%",
                                  //   height:
                                  //   product?.merchantId?.ActiveInactive &&
                                  //   product?.merchantId?.distributorStatus &&
                                  //   product?.merchantId?.distributorActiveInactive &&
                                  //   product?.merchantId?.adminActiveInactive 
                                  //       ? "auto"
                                  //       : "100%",
                                  //   color:
                                  //   product?.merchantId?.ActiveInactive &&
                                  //   product?.merchantId?.distributorStatus &&
                                  //   product?.merchantId?.distributorActiveInactive &&
                                  //   product?.merchantId?.adminActiveInactive 
                                  //       ? "black"
                                  //       : "white",
                                  //   position: "absolute",
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   justifyContent: "center",
                                  // }}
                                  >
                                    {/* <p style={{
                                  textAlign: 'center',
                                  fontSize: '18px',
                                }}><mark
                                style={{borderRadius: '5px', padding: '5px 20px', background: 'white'}}
                                >Closed</mark></p> */}
                                  </div>

                                  {console.log(product?.image[0], "product?.image[0]")}
                              <img
                                src={
                                  product?.image[0] ? product?.image[0] : noimage
                                }
                                className="card-img-top w-100 h-50 p-1 "
                                alt={product?.itemName}
                                onClick={() =>
                                  navigate(`/product/${product?._id}`, {
                                    state: { product: product },
                                  })
                                }
                              />
                              {product?.categoryId?.title === "Veg" ? (
                                <img
                                  src={product?.categoryId?.image}
                                  className="position-absolute top-0 end-0 m-2"
                                  style={{ width: "20px" }}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={product?.categoryId?.image}
                                  className="position-absolute top-0 end-0 m-2"
                                  style={{ width: "20px" }}
                                  alt=""
                                />
                              )}
                              <div className="card-body  p-3">
                                <small>
                                  <h6 className="text-capitalize card-title">
                                    {product?.itemName}
                                  </h6>
                                </small>
                                <h6>
                                  {/* <small>{product.unitQuantityId.title}</small>{" "} */}
                                </h6>
                                <div className="d-flex justified-content-sm-end text-dark ">
                                  <h6 className=" m-1 text-bold">
                                    <small> ₹{product?.itemPrice}</small>
                                  </h6>
                                  {cart.some(
                                    (p) => p._id === product?._id && p.count > 0
                                  ) ? (
                                    <div className="text-body1 fw-bolder text-center ">
                                      <AiOutlineMinus
                                        className="  ms-2 "
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) =>
                                          handleQtyChange(e, product, "-")
                                        }
                                      />
                                      <small className="  ms-2">
                                        {
                                          cart.filter(
                                            (p) =>
                                              p._id === product?._id &&
                                              p.count > 0
                                          )[0].count
                                        }
                                      </small>
                                      <AiOutlinePlus
                                        className=" ms-2 "
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) =>
                                          handleQtyChange(e, product, "+")
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {console.log(cart, "..")}
                                  {product?.status === false ? (
                                    <span
                                      style={{
                                        fontSize: "11px",
                                        color: "#475f7b",
                                      }}
                                    >
                                      Product is not available
                                    </span>
                                  ) : cart.some(
                                      (p) =>
                                        p._id === product?._id && p.count > 0
                                    ) ? (
                                    <button
                                      className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                                      style={{
                                        borderRadius: "5px",
                                        color: "#003C7E",
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => {
                                        setSuccessMsg(true);
                                        setMsg("Product Removed SuccessFully");

                                        timeout = displaySuccessMsg(
                                          setSuccessMsg,
                                          timeout
                                        );
                                        deleteFromCart(product);
                                        setCart(getCart());
                                      }}
                                    >
                                      Remove
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-sm dright bs text-bold text-bold text-success fw-bolder"
                                      style={{
                                        borderRadius: "5px",
                                        color: "#003C7E",
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => handleAddToCart(product)}
                                    >
                                      Add
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    ))
                  ) : (
                    <NoProduct />
                  )}
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={products?.length}
            marginPagesDisplayed={2} // at end showing
            pageRangeDisplayed={5} // in middle
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductList;
