import React, { useContext, useState } from "react";
//import Draggable from "react-draggable";
import { API } from "../../config/api";
import './myProfile.css'
import leftImg from '../../assets/images/profileImg.png'
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import Input from "@mui/material/Input";
// import { FormGroup } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
import { StateContext } from "../../context/ContextProvider";

import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { showLoader } from "../../compenent/helpers/loading";
import { showErrorMsg, showSuccessMsg } from "../../compenent/helpers/message";
//import { MdFormatLineSpacing } from "react-icons/md";
//import { useNavigate } from "react-router-dom";
const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;

function MyProfile() {
  const { user, setUser } = useContext(StateContext);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };

  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    }, 2000);
    return timeout;
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please enter First Name"),
    lastname: Yup.string().required("Please enter Last Name"),
    email: Yup.string()
      .required("Please enter Email")
      .email("Please enter valid Email ID"),
    gender: Yup.string()
      .required("Please select Gender")
      .test("test-gender", "Please select any one of gender", function (value) {
        if (value !== "MALE" && value != "FEMALE") {
          return false;
        }
        return true;
      }),
    mobile: Yup.string()
      .required("Please enter Mobile number")
      .matches(phoneRegex, "Please enter valid Mobile Number"),
  });
  const formik = useFormik({
    initialValues: {
      firstname: user.data.firstName,
      lastname: user.data.lastName,
      gender: user.data.gender,
      email: user.data.email,
      mobile: user.data.mobile,
    },
    validationSchema: validationSchema,
    onSubmit: async (userinfo) => {
      setLoading(true);
      const userdata = {
        firstName: userinfo.firstname,
        lastName: userinfo.lastname,
        email: userinfo.email,
        mobile: userinfo.mobile,
        gender: userinfo.gender,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": sessionStorage.getItem("token"),
        },
      };

      await axios
        .put(`${API}/auth/edit-profile`, userdata, config)
        .then(async (res) => {
          if (res.data.status == "SUCCESS") {
            await axios.get(`${API}/auth/get-profile`, config).then((res) => {
              setUser(res.data);
              setMsg("Profile updated succesfully");
              timeout = displaySuccessMsg(setSuccessMsg, timeout);
            });

            setLoading(false);
          }
        })
        .catch((err) => {
          // setMsg('Try again Later');
          // timeout = displayErrMsg(setErrMsg, timeout);
          // setLoading(false)
        });
    },
  });
  const handleGender = (e) => {
    formik.setFieldValue("gender", e.target.value, true);
  };
  return (
    <div className="main-profile-container">
      <div className="card-body my-2">
        <div className="row profile-inner-contaienr">
          <div className="col-md-3 mb-4 left-img-section">
            <img src={leftImg} />
          </div>
          <div className="col-md-6 right-side-secton">
            <div className="mb-4 bs">  {/* <div className="card mb-4 bs"> */}
              <div
                // className="card-header py-3 "
                // style={{ backgroundColor: "#003C7E" }}
              >
                <h5 className="mb-auto headerProfile">My Profile</h5>
              </div>
              <div className="  px-2 pt-4">
                <form
                  style={{
                    width: "100%",
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  {successMsg && showSuccessMsg(msg, successMsg)}
                  {loading && showLoader()}
                  {errMsg && showErrorMsg(msg, errMsg)}
                  <div className="row borderremove">
                    <div className="row borderremove">
                      <div className="form-outline mb-1" id="AddressElemet-1">
                        <label className="form-label">
                          Personal Information*
                        </label>
                        <br />
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="First Name"
                            id="firstname"
                            name="firstname"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.firstname &&
                              formik.touched.firstname
                            }
                            helperText={
                              formik.errors.firstname &&
                              formik.touched.firstname
                                ? formik.errors.firstname
                                : ""
                            }
                            className="form-control "
                            style={{ height: "2rem", width: "40%" }}
                          ></input>

                          <input
                            type="text"
                            placeholder="Last Name"
                            id="lastname"
                            name="lastname"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.lastname && formik.touched.lastname
                            }
                            helperText={
                              formik.errors.lastname && formik.touched.lastname
                                ? formik.errors.lastname
                                : ""
                            }
                            className="form-control  ms-2"
                            style={{ height: "2rem", width: "40%" }}
                          ></input>
                        </div>

                        <br />
                      </div>
                    </div>
                    <div
                      className="genderbox pb-1 ms-2"
                      id="gender"
                      name="gender"
                      error={formik.errors.gender && formik.touched.gender}
                      helperText={
                        formik.errors.gender && formik.touched.gender
                          ? formik.errors.gender
                          : ""
                      }
                    >
                      <label className="form-label">Gender</label>
                      <br />
                      <div className="d-flex ">
                        <input
                          type="radio"
                          id="male"
                          name="male"
                          value="MALE"
                          checked={
                            formik.values.gender === "MALE" ? true : false
                          }
                          onChange={(e) => handleGender(e)}
                        />
                        <label className="p-2">Male</label>
                        <input
                          type="radio"
                          id="female"
                          name="female"
                          value="FEMALE"
                          checked={
                            formik.values.gender === "FEMALE" ? true : false
                          }
                          onChange={(e) => handleGender(e)}
                        />
                        <label className="p-2">Female</label>
                      </div>
                      <br />
                    </div>
                    <div className="row borderremove">
                      <div className="form-outline mb-4">
                        <label className="form-label">Email Address*</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Email Address"
                          id="email"
                          className="form-control  "
                          style={{
                            height: "2rem",
                            width: "60%",
                            textTransform: "lowercase",
                          }}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.errors.email && formik.touched.email}
                          helperText={
                            formik.errors.email && formik.touched.email
                              ? formik.errors.email
                              : ""
                          }
                        ></input>
                      </div>
                    </div>
                    <div className="row borderremove">
                      <div className="form-otline mb-4">
                        <label className="form-label">Mobile No*</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Mobile No"
                          id="mobile"
                          className="form-control "
                          style={{ height: "2rem", width: "60%" }}
                          name="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.errors.mobile && formik.touched.mobile}
                          helperText={
                            formik.errors.mobile && formik.touched.mobile
                              ? formik.errors.mobile
                              : ""
                          }
                        ></input>
                      </div>
                    </div>
                    <div className="row borderremove">
                      {/* <Link to="/PaymentMethod"> */}

                      <button
                        type="submit"
                        className="btn updatebtn col-sm-6"
                      >
                        UPDATE
                      </button>
                      <div className="col-sm-6"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
