import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./addAdress.css";
import { clearSessionOnCheckout } from "../../compenent/helpers/localstorage";
import { showSpinner } from "../../compenent/helpers/loading";
import axios from "axios";
import { StateContext } from "../../context/ContextProvider";
import { API } from "../../config/api"
import {
  getItemsFromLocalStorage,
  setItemToLocalStorage,
} from "../../utils/localStorageActions";

function AddAdress() {
  const navigate = useNavigate();

  const [address, setAddress] = useState(
    getItemsFromLocalStorage("address") || {
      location: "",
      pincode: "",
      flat: "",
      area: "",
    }
  );

  const { location, pincode, area, flat } = address;

  const addressHandler = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  // const [location, setLocation] = useState("");
  // const [pincode, setPincode] = useState("");
  // const [flat, setFlat] = useState("");
  // const [area, setArea] = useState("");

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setCart } = useContext(StateContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setItemToLocalStorage("address", address);

    let emptObj = [];
    const localstorecart = JSON.parse(localStorage.getItem("cart"));
    console.log("localstorecart", localstorecart);
    console.log(isSubmitting);
    for (let i = 0; i < localstorecart.length; i++) {
      emptObj.push({
        productId: localstorecart[i]._id,
        quantity: localstorecart[i].count,
      });
    }

    let subTotal = localstorecart
      .map((product, index) => product?.itemPrice * product?.count)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);

    let cartTotalPrice = (
      subTotal -
      localstorecart
        .map((product, index) => {
          if (product?.discountType === "FIXED") {
            return parseInt(product?.customerDiscount);
          } else {
            return (
              (parseInt(product?.itemPrice) *
                parseInt(product?.count) *
                parseInt(product?.customerDiscount)) /
              100
            );
          }
        })
        .reduce((a, b) => a + b)
        .toFixed(0) +
      (subTotal * parseFloat(localstorecart[0]?.gst)) / 100 +
      0
    ).toFixed(0);

    // =====================checkout handler====================================
    const myuserId = window.localStorage.getItem("userId");
    const config = { headers: { "Content-Type": "application/json" } };
    setIsSubmitting(true);
    e.preventDefault();

    const OrderAPI = `${API}/order/add`;

    const payload = {
      userId: myuserId,
      merchantId: localstorecart[0].merchantId,
      distributorId: window.sessionStorage.getItem("distributorId"),
      productDetails: emptObj,
      totalPrice: parseInt(cartTotalPrice),
      city: location,
      address: area,
      pincode: pincode,
      paymentMode: "NOT SELECTED",
    };
    console.log(payload);
    setLoading(true);
    await axios
      .post(OrderAPI, payload, config)
      .then((res) => {
        const successResponse = res.data.status.toLowerCase() === "success";
        const errorResponse = res.data.status.toLowerCase() === "error";
        console.log("orderdata response", res.data);
        if (successResponse) {
          // setErrMsg(true);
          // setMsg(res?.data?.message || "Your Order  placed successfully");
          // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setTimeout(() => {
            // setErrMsg(false);

            clearSessionOnCheckout();
            setCart([]);
            setLoading(false);
            navigate("/orderplaced");
          }, 5000);
        } else if (errorResponse) {
          // setErrMsg(true);
          // setMsg(res?.data?.message || "Sorry unable to place your Order");
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }

        //setIsSubmitting(false);
      })
      .catch((err) => {
        console.log("orderdata err", err);
        // setErrMsg(true);
        // setMsg("server error : Sorry unable to send order");
        // setIsSubmitting(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      });
    //changes *******************ended
  };
  return (
    <div className="container-sm">
      {/* <div className="row">
        <div className="col-sm-12 "> */}

      {/* <div className="card shipcard"> */}
      {loading && showSpinner()}
      <div className="card-body ">
        <div className="row">
          <div className="col-md-2 mb-4"></div>
          <div className="col-md-8 ">
            <div className="card mb-4 bs">
              <div
                className="card-header py-3 "
                style={{ backgroundColor: "#003C7E" }}
              >
                <h5 className="mb-auto text-white" style={{ color: "white" }}>
                  Add Address
                </h5>
              </div>
              <div className="card-body px-2 pt-4">
                <form
                  style={{
                    width: "100%",
                    backgroundColor: "whitesmoke",
                    //   borderRadius: "25px",
                  }}
                  onSubmit={handleSubmit}
                >
                  <div className="row mb-4">
                    <div className="row">
                      <div className="form-outline mb-1" id="AddElemet-1">
                        <label className="form-label">
                          DELIVERING ORDER TO*
                        </label>
                        <input
                          type="text"
                          placeholder="Enter a Location"
                          required
                          onChange={addressHandler}
                          name="location"
                          value={location}
                          className="form-control "
                          style={{ height: "2rem", width: "50%" }}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-outline mb-1" id="AddElemet-2">
                        <label className="form-label" id="form-elemet-2">
                          Pincode*
                        </label>
                        <input
                          type="number"
                          placeholder="Pincode"
                          required
                          name="pincode"
                          onChange={addressHandler}
                          className="form-control "
                          style={{ height: "2rem", width: "50%" }}
                          value={pincode}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-otline mb-1" id="AddElemet-3">
                        <label className="form-label">
                          Flot No, Bulding Name*
                        </label>

                        <input
                          type="text"
                          placeholder="Flot No, Bulding Name"
                          required
                          name="flat"
                          value={flat}
                          onChange={addressHandler}
                          className="form-control "
                          style={{ height: "2rem", width: "50%" }}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form outline mb-1" id="AddElemet-4">
                        <label className="form-label">Area/ Locality*</label>

                        <input
                          type="text"
                          placeholder="Area/ Locality"
                          required
                          name="area"
                          value={area}
                          onChange={addressHandler}
                          className="form-control  "
                          style={{ height: "2rem", width: "50%" }}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="AddElemet-5"
                    >
                      Place order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-2 mb-4"></div>
        </div>
      </div>
    </div>
  );
}

export default AddAdress;
